<template>
  <CCol v-for="(item, key) in items" :key="key">
    <CRow class="jobTitleRow">
      <CCol class="col-7 col-md-8 col-lg-7 col-xl-8 col-xxl-10">
        <CNSelect
          v-model="item.position_id"
          :options="availableOptions(item)"
          :error="errors['position_id_' + key]"
          :invalid="!!errors['position_id_' + key]"
          label="Select Job Title"
          @change="validateField(key)"
        />
      </CCol>
      <CCol v-if="showAdditional === 'checkbox'" class="col-auto mt-3">
        <CNCheckbox v-model="item.mandatory" :disabled="!item.position_id || disabled || item.has_condition" />
      </CCol>
      <CCol v-if="showAdditional === 'na'" class="col-auto position-relative hideCheckbox">
        <span v-if="!key" class="position-absolute titleRequired"> Required </span>
        <span class="checkboxText">N/A</span>
      </CCol>
      <CCol class="col-auto text-center" style="margin-top: 14px">
        <CIcon name="cilX" :class="[item.position_id && !disabled ? 'text-error' : 'text-disable', 'pointer']" @click="handleDelete(key)" />
      </CCol>
    </CRow>
  </CCol>
</template>

<script>
import CNSelect from '@/components/ui/CNSelect/CNSelect'
import CNCheckbox from '@/components/ui/CNCheckbox/CNCheckbox'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { DEF_ITEM } from '../../approval/constants'

export default {
  name: 'JobTitleRow',
  components: {
    CNSelect,
    CNCheckbox,
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAdditional: {
      type: String,
      default: 'checkbox',
    },
    validateKeys: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      validator: {},
      errors: {},
    }
  },
  computed: {
    ignore() {
      return this.items.map((el) => el.position_id)
    },
    validate() {
      return this.validateKeys.length > 0
    },
  },
  mounted() {
    this.$nextTick(() => this.setRules())
  },
  methods: {
    setRules() {
      if (this.validate) {
        let obj = {}
        this.validateKeys.forEach((el) => {
          obj['position_id_'+el] = [rules.required, rules.number]
        })

        this.validator = validator(obj)
      }
    },
    availableOptions(item) {
      return this.options.filter((el) => {
        if (!this.ignore.includes(el.value) || el.value === item.position_id)
          return el
      })
    },
    pushRow(){
      if (this.items.every((el) => !!el.position_id) && this.items.length < this.options.length)
        this.items.push(this.$deepClone(DEF_ITEM))
    },
    handleDelete(key) {
      this.$nextTick(() => {
        this.items.splice(key, 1)
        this.pushRow()
      })
    },
    validateAll() {
      let res = []
      this.validateKeys.forEach((el) => {
        this.validateField(el)
        let t = this.validator.validate('position_id_' + el, this.items[el]['position_id'])
        res.push(!!t)
      })
      return res
    },
    validateField(index) {
      this.$nextTick(() => {
        this.pushRow()
        this.errors['position_id_'+index] = this.validator
          .validate('position_id_'+index, this.items[index]['position_id'])
      })
    },
    clearErrors() {
      this.errors = {}
    }
  },
}
</script>

<style lang="scss" scoped>
.jobTitleRow {
  .hideCheckbox {
    margin-top: 13px;
    .titleRequired,
    .checkboxText {
      font-style: normal;
      line-height: 20px;
      color: #9aa8b5;
    }
    .titleRequired {
      font-weight: 600;
      font-size: 12px;
      top: -38px;
      left: 15px;
    }
    .checkboxText {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: -0.003em;
      margin: -2px 17px 0 17px;
    }
  }
  }
</style>
