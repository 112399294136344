<template>
    <CRow :xs="{ cols: 1, gutter: 4 }">
        <ConditionModal :job-titles="options" :checked-titles="positionWithoutCondition" :show="showConditionModal"
                        :item="selectedCondition" :condition-name="conditionName" @close="handleCancelCondition"
                        @save="(formData) => handleSaveCondition(formData, 'scope_approvals')"/>
        <CCol class="col-12 col-xl-10 col-xxl-10">
            <CNInput v-model="form.name" :error="validationErrors.name" :invalid="!!validationErrors.name"
                     label="Please name this scope approval workflow" @blur="validateField('name')" required/>
        </CCol>
        <CCol>
            <CNListItem number="1"
                        text="Who are the people in your organization that may draft a scopes of work for a vendor on a project?"/>
        </CCol>

        <JobTitleRow ref="ScopeEditing" :validate-keys="validateIndexes" :options="options" :items="form.scope_editing"
                     :show-additional="'na'"/>

        <CCol class="p-0">
            <CDivider/>
        </CCol>

        <CCol>
            <CNListItem number="2"
                        text="Who are the people in your organization that will approve the scope of work to be incorporated into your contract packages?"/>
        </CCol>
        <JobTitleRow ref="ScopeApprovals" :validate-keys="validateIndexes" :options="options"
                     :items="form.scope_approvals"/>

        <CFormText v-if="validationErrors['scope_approvals.mandatory.no_checked']" class="text-error mt-3">
            {{ validationErrors['scope_approvals.mandatory.no_checked'] }}
        </CFormText>

        <CCol class="p-0">
            <CDivider/>
        </CCol>
        <CCol>
            <CNListItem number="3"
                        text="Who are the people in your organization that will be required to approve the scope of work? Use the checkboxes to the right of the job titles."/>
        </CCol>
        <CCol class="p-0">
            <CDivider/>
        </CCol>

        <CCol>
            <CNListItem number="4"
                        text="Who are the people in your organization that will approve the scope of work for project with larger estimated budgets?"/>
        </CCol>
        <CCol style="min-height: 100px">
            <CRow>
                <CCol class="col-auto">
                    <CButton variant="ghost" style="padding: 0 !important;" @click="showConditionModal = true">
                        <svg width="17" height="17" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_2570_35000)">
                                <path
                                    d="M20.625 11.75H12.75V3.875H11.25V11.75H3.375V13.25H11.25V21.125H12.75V13.25H20.625V11.75Z"
                                    fill="#677A89"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2570_35000">
                                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span class="additionBtn"> Add conditions </span>
                    </CButton>
                </CCol>
                <CCol v-for="(condition, index) in form.scope_approvals.filter(el => el.has_condition)" :key="index"
                      class="col-auto my-auto">
                    <Tag :condition="condition" :index="index" @delete="conditionDelete(index, 'scope_approvals')"
                         @edit="handleEditCondition"/>
                </CCol>
            </CRow>
        </CCol>
    </CRow>
</template>

<script>
import JobTitleRow from '../components/JobTitleRow'
import formMixin from '../../approval/formMixin'

export default {
    name: 'Form',
    components: {JobTitleRow},
    mixins: [formMixin],
    data() {
        return {
            validateIndexes: [0],
        }
    },
    watch: {
        'form.scope_approvals': {
            handler: function (val, old) {
                if (val.length > 1) this.validateSectionTwoMandatory()
            },
            deep: true,
        },
    },
    computed: {
        checked() {
            return this.form.scope_approvals.map((el) => el.position_id)
        },
        positionWithoutCondition() {
            return this.form.scope_approvals
                .filter((el) => el.position_id && el.has_condition)
                .map((obj) => obj.position_id)
        },
        conditionName() {
            return this.form.scope_approvals.map((el) => el.condition_name)
        },
    },
    methods: {
        validateSectionTwoMandatory() {
            let check = this.form.scope_approvals.filter((el) => !el.has_condition).some((item) => item.mandatory)

            this.validationErrors['scope_approvals.mandatory.no_checked'] = check
                ? ''
                : 'At least one person without approval conditions is required to approve the scope. Please use checkboxes to the right of job titles'

            return check
        },
        deepValidate() {
            let res = []
            res = res.concat(this.validateRef('ScopeApprovals'), this.validateRef('ScopeEditing'))
            const mainValidate = this.validator.validateAll(this.form)
            this.validationErrors = mainValidate.validationErrors
            res.push(mainValidate.hasErrors)
            return res.some((el) => el === true) || !this.validateSectionTwoMandatory()
        },
        validateRef(refName) {
            let el = Array.isArray(this.$refs[refName])
                ? this.$refs[refName][0]
                : this.$refs[refName]

            return el.validateAll()
        },
        clearErrorsRef() {
            let arr = ['ScopeApprovals', 'ScopeEditing']

            arr.forEach((item) => {
                this.$refs[item].validator.errors = this.$refs[item].errors = {}
            })
            this.validationErrors = {}
        },
    },
}
</script>

<style scoped>
.additionBtn {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #1c262f;
}
</style>
